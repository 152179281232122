.App {
    margin-top: 15px;
}

.App .navbar {
    padding-left: 0;
}

.App .navbar-brand h1 {
    font-family: 'Crimson Text', serif;
    font-weight: bold;
    font-size: 6vw;
    align-items: center;
}

.App .navbar-brand img {
    max-width: 15%;
    vertical-align: middle;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    padding-right: 10px;
    margin-bottom: 0.5rem;
}

/* For width 1024px and larger: */
@media only screen and (min-width: 1024px) {
    .App .navbar-brand h1 {
        font-size: 2.5rem;
    }

    .App .navbar-brand img {
        max-width: 60px;
    }
}

.App .twitter_logo {
    width: 30px;
    height: 30px;
    margin: 8px;
    vertical-align: middle;
}

.App .instagram_logo {
    width: 30px;
    height: 30px;
    margin: 8px;
    vertical-align: middle;
}
