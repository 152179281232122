.Home .venues {
    text-align: left;
}

.Home h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Home .covid19_update {
    border: 3px;
    border-radius: 25px;
    border-style: dashed;
    border-color: LightCoral;
    padding: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
}

.Home .covid19_update h4 {
    color: LightCoral;
}

.Home .covid19_update p {
    margin-block-end: 0;
    margin-inline-end: 0;
}

.Home .toggle_delivery_takeaway_only {
    text-align: right;
}

.Home .toggle_delivery_takeaway_only .superscript_new {
    color: Red;
    font-size: 9pt;
    font-weight: bold;
    vertical-align: super;
}

.Home .delivery_ok {
    color: Green;
    font-weight: bold
}

.Home .delivery_none {
    color: LightCoral;
}

.Home .district {
    margin-top: 40px;
}

.Home .district h2 {
    text-align: center;
}

.Home .venue {
    padding-top: 1em;
}

.Home .active_venue {
    text-align: right;
    font-weight: bold;
    color: LimeGreen;
}

.Home .inactive_venue {
    text-align: right;
    font-weight: bold;
    color: Red;
}

.Home .photo img  {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 75%;
    border-radius: 8px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

/* For width 1024px and larger: */
@media only screen and (min-width: 1024px) {
    .Home .photo img  {
        float: right;
        max-width: 400px;
    }
}

.Home .venue_name {
    padding-bottom: 0.5em;
    text-align: center;
}

.Home .venue_name a {
    color: #000000;
}

.Home .external_link {
    vertical-align: super;
    font-size: 14px;
}

.Home .update_delete {
    text-align: right;
}

.Home .contact {
    text-align: center;
}

.Home .contact .fa-envelope {
    font-size: 30px;
    vertical-align: middle;
    margin: 8px;
}
.Home .contact a {
    color: inherit;
}

.Home .copyright {
    text-align: center;
}
